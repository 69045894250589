.who-is-who {
    
    .freelancer {
        padding: 15px;
        display: flex;
        @media (max-width: 768px) {
            flex-direction: column;
        }

        img { 
            max-width: 230px;
        }

        > div:nth-child(1) {
            flex: 1;
        }

        > div:nth-child(2) {
            flex: 0;
            padding: 15px;
            @media (max-width: 768px) {
                text-align: center;
            }
        }


    }
}