.single-client {

    .client-view {

        display: flex;
        flex-direction: row;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        .client-view-side {
            @media (max-width: 768px) {
                max-width:100%;
            }
            flex: 1;
            max-width: 20%;
            padding: 20px;
            background: #f8f8f8;
            font-size: 14px;
            line-height: 20px;
            color: #555;
            h2 {
                font-size: 16px;
                color: #444;
            }
            ul {
                margin: 0px;
                padding: 0px;
                list-style-type: none;
                li {
                    i { 
                        padding-right: 15px;
                    }
                }
            }
        }
        .client-view-content {
            flex: 1;
            padding-left: 15px;
            padding-right: 15px;
        }

    }


}

.single-client-documents {

    font-size: 14px;

    

    .document-table {


        @media (max-width:768px) {
            .documents-header {
                display: none !important;
            }
    
            .document-table-row {
                flex-direction: column;
            }
            
        }
    
        @media (min-width: 768px) {
            .documents-header {
                
                font-weight: bold;
            }
    
            .document-table-row {
                flex-direction: row;
                border-bottom: 1px solid #eee;
                .document-table-item {
                    padding: 5px;
                    &:nth-child(1) {
    
                    }
                    &:nth-child(2) {
                        max-width: 15%;
                        border-left: 1px solid #eee;
                    }
                    &:nth-child(3) {
                        max-width: 15%;
                        border-left: 1px solid #eee;
                    }
                    &:nth-child(4) {
                        border-left: 1px solid #eee;
    
                    } 
                    .document-table-item-label {
                        display: none;
                    }
                }
            }
        }

        .document-table-row {
            display: flex;
            
            .document-table-item {
                flex: 1;
                display: flex;
                flex-direction: row;
                .document-table-item-label {
                    flex: 0;
                    padding-right: 10px;
                }
                .document-table-item-content {
                    flex: 1;
                }
            }

        }

    }


}

.single-client-team-contact {
    
    .team-members {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
        align-items: space-around;
        .email-checkbox {
            flex: 1;
            margin-right: 15px;
            border: 1px solid #eee;
            margin-bottom: 15px;
            @media (max-width:768px) {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
            }
        }
    }

    .other-receivers {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .email-checkbox {
            flex: 1;
            border: 1px solid #eee;
            margin-bottom: 15px;
            @media (max-width:768px) {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
            }
        }
    }

    .message {

        .message-text {
            margin-top: 15px;
        }

    }

    .message-buttons {
        padding: 15px;
        min-height: 35px;
    }

}

.single-client-care-plan {

    .careplan-header {

        background: rgba(255,255,255,0.8);
        position: sticky;
        top: -15px;
        display: flex;
        flex-direction: row;
        z-index: 100;
        justify-content: flex-end;
        .careplan-buttons {
            padding: 15px;
            background: #FFF;
            position: sticky;
            flex: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            button {
                flex: 1;
                display: block;
            }
        }
    }

    .care-description {

        > div {
            margin-bottom: 20px;
        }

    }

    .care-description-group {

        .care-description-item {
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            div:first-child {
                flex: 0;
            }
            div {
                flex: 1;
            }

        }

    }

}