.home {
    display: flex;
    flex-direction: row;
    margin: 0px;
    @media only screen and (max-width: 600px) { 
        flex-direction: column;
    }
    .card-body {
        margin: 10px;
        flex: 1;
        .namelist {
            ul {
                padding: 0px;
                margin: 0px;
                list-style-type: none;
                li {
                    cursor: pointer;
                    padding: 10px 20px;
                    &:nth-child(odd) {
                        background: rgba(0,0,0,0.05)
                    }
                    &:hover {
                        background: #2d0b4a;
                        a {
                            color: #FFF;
                        }
                    }
                }
            }


        }
    }

}